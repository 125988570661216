import axios, { AxiosResponse } from 'axios';
import {
    CheckoutPaymentEditModel,
    CheckoutDeliveryEditModel,
    CheckoutAddressEditModel,
    PaymentType,
    CheckoutCountryModel,
    CheckoutProcessOrderModel,
    DisabledDatesModel,
    DisabledDatesRequestModel,
    AddressInfoDTO,
    CheckoutProfileEditModel,
} from '@/models/Checkout';
import { DeliveryType } from '@/models/Checkout';
import { validate } from 'vee-validate';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';

export default class CheckoutService {
    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/checkout`;
    }

    public getCheckoutProfileEditModel(args: any = {}): CheckoutProfileEditModel {
        return {
            id: args.id,
            fields: args.fields ?? [],
        }
    }

    public getCheckoutAddressEditModel(args: any = {}): CheckoutAddressEditModel {
        return {
            phone: args.phone ?? '',
            email: args.email ?? '',
            reference: args.reference ?? '',
            comment: args.comment ?? '',
            name: args.name ?? '',
            address: args.address ?? '',
            alternativeaddress: args.alternativeaddress ?? '',
            city: args.city ?? '',
            zipcode: args.zipcode ?? '',
            region: args.region ?? '',
            country: args.country ?? '',
            invoicedetailsisdelivery: args.invoicedetailsisdelivery ?? true,
            deliveryname: args.deliveryname ?? '',
            deliveryaddress: args.deliveryaddress ?? '',
            deliveryalternativeaddress: args.deliveryalternativeaddress ?? '',
            deliverycity: args.deliverycity ?? '',
            deliveryzipcode: args.deliveryzipcode ?? '',
            deliveryregion: args.deliveryregion ?? '',
            deliverycountry: args.deliverycountry ?? '',
            memberemail: args.memberemail ?? '',
        };
    }

    public getCheckoutDeliveryEditModel(args: any = {}): CheckoutDeliveryEditModel {
        return {
            deliverytype: args.deliverytype ?? '',
            ispackageshop: false,
            ispickup: false,
            extrafields: args.extrafields ?? {},
        };
    }

    public getCheckoutPaymentEditModel(args: any = {}): CheckoutPaymentEditModel {
        return {
            paymenttype: args.paymenttype ?? '',
            transactionid: args.transactionid ?? '',
        };
    }

    public getCheckoutProcessOrderModel(args: any = {}): CheckoutProcessOrderModel {
        return {
            continueurl: args.continueurl ?? '',
            isreadyforpayment: args.isreadyforpayment ?? false,
        };
    }

    public async getProfile(): Promise<CheckoutProfileEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getprofile`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getAddress(): Promise<CheckoutAddressEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getaddress`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getAddresses(): Promise<AddressInfoDTO[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getaddresses`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getSubscribedToNewsletter(): Promise<boolean> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getsubscribedtonewsletter`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getDelivery(): Promise<CheckoutDeliveryEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getdelivery`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
    public async getPayment(): Promise<CheckoutPaymentEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getPayment`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async updateProfile(args: CheckoutProfileEditModel): Promise<CheckoutProfileEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/updateprofile`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async updateAddress(args: CheckoutAddressEditModel): Promise<CheckoutAddressEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/updateaddress`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async updateSubscribedToNewsletter(args: boolean): Promise<boolean> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/updatesubscribedtonewsletter?subscribe=` + args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async updateDelivery(args: CheckoutDeliveryEditModel): Promise<CheckoutDeliveryEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/updatedelivery`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async updatePayment(args: CheckoutPaymentEditModel): Promise<CheckoutPaymentEditModel> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/updatepayment`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async processorder(): Promise<CheckoutProcessOrderModel> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/processorder`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getDeliveryTypes(): Promise<DeliveryType[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getdeliverytypes`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getDeliveryDate(): Promise<Date> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getdeliverydate`);
            return new Date(res.data);
        } catch (e) {
            console.log(e);
        }
    }
    public async updateDeliveryDate(args: Date): Promise<Date> {
        try {
            const res: AxiosResponse<Date> = await axios.post(`${this.endpoint}/updatedeliverydate`, args);
            return res.data ? new Date(res.data) : null;
        } catch (e) {
            console.log(e);
        }
    }
    public async getDisabledDeliveryDates(): Promise<DisabledDatesModel> {
        try {
            const res: AxiosResponse<DisabledDatesRequestModel> = await axios.get(
                `${this.endpoint}/getdisableddeliverydates`
            );
            return {
                mindate: res.data.mindate ? new Date(res.data.mindate) : null,
                maxdate: res.data.maxdate ? new Date(res.data.maxdate) : null,
                disableddates: res.data.disableddates?.map(dateString => new Date(dateString)) ?? [],
                disabledweekdays: res.data.disabledweekdays ?? [],
            };
        } catch (e) {
            console.log(e);
        }
    }

    public async getPaymentTypes(): Promise<PaymentType[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getpaymenttypes`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getCountries(): Promise<CheckoutCountryModel[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getcountries`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async addressIsValid(address: CheckoutAddressEditModel): Promise<boolean> {
        try {
            if (address.name.length === 0) return false;
            if (address.address.length === 0) return false;
            if (address.zipcode.length === 0) return false;
            if (address.city.length === 0) return false;
            if (address.country.length === 0) return false;
            if (address.phone.length === 0) return false;
            if (address.email.length === 0) return false;
            const emailValidation = await validate(address.email, 'email');
            if (!emailValidation.valid) return false;
            if (address.invoicedetailsisdelivery === false) {
                if (address.deliveryname.length === 0) return false;
                if (address.deliveryaddress.length === 0) return false;
                if (address.deliveryzipcode.length === 0) return false;
                if (address.deliverycity.length === 0) return false;
                if (address.deliverycountry.length === 0) return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    public async deliveryIsValid(delivery: CheckoutDeliveryEditModel): Promise<boolean> {
        try {
            if (delivery.deliverytype.length === 0) return false;
            if (delivery.ispackageshop) {
                if (delivery.extrafields?.['packageshop-shopid']?.length === 0) return false;
                if (delivery.extrafields?.['packageshop-name']?.length === 0) return false;
                if (delivery.extrafields?.['packageshop-address1']?.length === 0) return false;
                if (delivery.extrafields?.['packageshop-zipcode']?.length === 0) return false;
                if (delivery.extrafields?.['packageshop-city']?.length === 0) return false;
            }
        } catch (e) {
            console.log(e);

            return false;
        }
        return true;
    }

    public async paymentIsValid(payment: CheckoutPaymentEditModel): Promise<boolean> {
        try {
            if (payment.paymenttype.length === 0) return false;
        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    }

    public async getTerms(): Promise<string> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/getterms`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async validateEAN(provider: string, paymenttype: string, ean: string): Promise<any> {
        try {
            const res: AxiosResponse<any> = await axios.get(`/api/thirdparty/${provider.toLowerCase()}/validate?homenodeid=${CbxVariablesModule.VARIABLES.apiContextKey}&paymenttype=${paymenttype}&ean=${ean}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
}
